// @flow
import * as React from "react";
import { DesktopMenuLayout } from "../layout/DesktopMenuLayout";
import { getWidht } from "./MobileFormMenu";
import { IconFacebook } from "../icons/IconFacebook";
import { IconLinkedIn } from "../icons/IconLinkedIn";
import { IconTwitter } from "../icons/IconTwitter";
import { IconLink } from "../icons/IconLink";
import { IconEmail } from "../icons/IconEmail";
import { SHARE_LINK } from "../helpers/constants";
import { motion } from "framer-motion";

type DesktopFormMenuProps = {
  actualStep: number;
  maxSteps: number;
};

export type ProgressBarProps = {
  variant: "form" | "gpt";
  actualStep: number;
  maxSteps: number;
};

export function ProgressBarDesktop({
  variant,
  actualStep,
  maxSteps,
}: ProgressBarProps) {
  let widthPercentage = `${getWidht(variant, actualStep, maxSteps)}%`;

  return (
    <div
      className={`flex-start flex h-2 flex-row items-center rounded-lg ${
        variant === "form" ? "bg-mint-green-25" : "bg-energy-orange-25"
      }`}
    >
      <motion.div
        initial={{ width: "0%" }}
        animate={{ width: widthPercentage }}
        transition={{ duration: 0.4 }}
        className={`block h-full rounded-lg ${
          variant === "form" ? "bg-mint-green" : "bg-energy-orange"
        }`}
        style={{
          width: `${getWidht(variant, actualStep, maxSteps)}%`,
        }}
      ></motion.div>
    </div>
  );
}

export type DesktopShareIconsProps = {
  url: string;
};

export function DesktopShareIcons({ url }: DesktopShareIconsProps) {
  return (
    <div className="flex flex-row justify-between gap-1.5">
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        target="_blank"
        rel="noreferrer"
      >
        <IconFacebook />
      </a>
      <a
        href={`https://linkedin.com/shareArticle?mini=true&url=${url}`}
        target="_blank"
        rel="noreferrer"
      >
        <IconLinkedIn />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?url=${url}`}
        target="_blank"
        rel="noreferrer"
      >
        <IconTwitter />
      </a>
      {/** copy link to clipboard */}
      <a
        href="#"
        className="cursor-pointer"
        onClick={() => {
          navigator.clipboard
            .writeText(url)
            .then(() => {
              alert("Skopírované do schránky");
            })
            .catch(() => {
              alert("Nepodarilo sa skopírovať");
            });
        }}
      >
        <IconLink />
      </a>
      {/** send by email */}
      <a
        href={`mailto:?subject=Máš zdravú pečeň? Sprav si 2 minútový test&body=Každý štvrtý človek je ohrozený touto neviditeľnou chorobou - prevencia je kľúčová. Zisti svoje riziko stukovatenej pečene cez tento bezplatný test. Okrem toho si môžeš nechať poradiť, ako sa starať o zdravie svojej pečene od virtuálneho trénera zdravia Zhiviho. Test nájdeš na tomto odkaze - www.zhivi.sk`}
      >
        <IconEmail />
      </a>
    </div>
  );
}

export function DesktopFormMenu({
  actualStep,
  maxSteps,
}: DesktopFormMenuProps) {
  return (
    <DesktopMenuLayout>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <p className="text-base font-bold leading-4 text-zhiva-brown">
            Progres
          </p>
          <ProgressBarDesktop
            variant={"form"}
            actualStep={actualStep}
            maxSteps={maxSteps}
          />
        </div>

        <div className="flex flex-col gap-4">
          <p className="text-base font-bold leading-4 text-zhiva-brown">
            Zdieľať
          </p>
          <DesktopShareIcons url={SHARE_LINK} />
        </div>
      </div>
    </DesktopMenuLayout>
  );
}

// @flow
import { motion, useAnimate } from "framer-motion";
import * as React from "react";
import { useEffect } from "react";
import { cn } from "../helpers/twMerge";
import { FAKE_LOADING_TIME } from "../helpers/constants";

type Props = {
  text: string;
  score?: number;
  value?: string;
  answerKey: string;
  onClick: (
    value: string | number,
    score: number | null,
    key: string,
    prompt?: string,
  ) => void;
  answered?: string | number;
  input?: boolean;
  answerSex?: string | null;
  sex?: string;
  prompt?: string;
};

export function AnswerBubble({
  text,
  score,
  value,
  onClick,
  answered,
  input,
  answerKey,
  answerSex,
  sex,
  prompt,
}: Props) {
  const [scope, animate] = useAnimate();

  function getSuffix(text: string) {
    switch (text) {
      case "vyska":
        return "cm";
      case "hmotnost":
        return "kg";
    }
  }

  useEffect(() => {
    if (input) {
      animate(
        scope.current,
        {
          translateX: [100, 0],
          opacity: [0, 1],
        },
        {
          duration: 0.4,
        },
      );
    }
  }, [input, animate, scope]);

  if (answerSex !== null && answerSex !== sex && !answered) {
    return null;
  }

  return (
    <motion.div
      ref={scope}
      animate={
        !input && {
          opacity: [0, 1],
          translateY: [100, -10, 0],
        }
      }
      transition={{
        type: "spring",
        mass: 1,
        stiffness: 100,
        damping: 15,
        delay: parseFloat(`0.${FAKE_LOADING_TIME}`) + 0.4,
        duration: 0.4,
      }}
      onClick={() => {
        if (!answered) {
          // Animate the bubble to translateX -300% and opacity 0
          animate(
            scope.current,
            {
              translateX: -400,
              opacity: 0,
            },
            {
              duration: 0.2,
            },
          ).then(() => {
            onClick(value ?? "", score ?? null, answerKey, prompt);
            animate(scope.current, {
              translateX: [100, 0],
              opacity: [0, 1],
              backgroundColor: ["#fff", "#F1FEFB"],
            });
          });
        }
      }}
      className={cn(
        "h-auto w-auto min-w-[75px] cursor-pointer rounded-[32px] border border-zhiva-brown bg-white px-4 py-3 text-center " +
          "hover:border-mint-green hover:bg-zhiva-brown-5 hover:text-mint-green " +
          "active:border-mint-green active:bg-mint-green-10 active:text-mint-green",
        {
          "border-mint-green bg-mint-green-10 text-mint-green":
            answered === (value ?? 0) || input,
          hidden: answered !== undefined && answered !== (value ?? 0) && !input,
        },
      )}
    >
      <span className="text-base font-semibold">
        {text} {getSuffix(answerKey)}
      </span>
    </motion.div>
  );
}

export const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://admingpt.zhiva.sk/api"
    : "https://backend.test/api";
export const SHARE_LINK =
  "https://mojapecen.zhiva.sk/?utm_source=zhivi.sk&utm_medium=buttons-desktop-left&utm_campaign=zhivi&utm_id=share";
export const SHARE_LINK_FINAL =
  "https://mojapecen.zhiva.sk/?utm_source=zhivi.sk&utm_medium=buttons-bottom&utm_campaign=zhivi&utm_id=share";

export const FAKE_LOADING_TIME = 800;
